import $j from 'jquery'
import jQueryBridget from 'jquery-bridget'
import Isotope from 'isotope-layout'
import Cookies from 'universal-cookie';
import _ from 'underscore';

navigationMobile();
checkCookie();

export function onRouteUpdate() {
  // Make Isotope a jQuery plugin
  jQueryBridget('isotope', Isotope, $j);

  if($j(".work-template").length) {
    initIsotope();
    triggerReset();
    resetIsotope();
    showHideMoreFilters();
    removeLoaderWorkPage();
    disableEnterKey();
    scrollDetect();
  }

  navigationTransparency();
  iframeLoader();
  anchor();

  $j(window).on('load', function() {

    if ($j(".work-template").length) {
      initIsotope();
      triggerReset();
      // resetIsotope();
      // showHideMoreFilters();
      // removeLoaderWorkPage();
      // disableEnterKey();
      // scrollDetect();
    }
  })

  $j(window).on('resize', function () {
    mobileNavHeight();
    homepageBannerHeight();
  }).resize();

  if($j('.banner-content .button').length){
    var origPos = $j('.banner-content .button').offset().top;
    stickyButton(origPos);

    $j(window).scroll(function () {
      stickyButton(origPos);
    });
  }

}

// - - - Reset isotope on first scroll to fix layout - - -
function scrollDetect() {
  var scroll = false;
  $j(window).scroll(function() {
    if (!$j(document).find(".js-filter-wrapper").hasClass("has-filter")) {
      if (scroll == false) {
        triggerReset();
        scroll = true;
      }
      return scroll;
    }
  })
}

// - - - Add smooth scroll on anchor link - - -
function anchor() {
  $j(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (!$j(this).hasClass("uk-lightbox-button")) {
      $j('html, body').animate({
        scrollTop: $j($j.attr(this, 'href')).offset().top - 100
      }, 500);
    }
  });
}

function navigationTransparency() {

  if (!$j("header").hasClass("team-member")) {
    $j(window).scroll(function () {
      var $height = $j(window).scrollTop();
      if($height > 150) {
        $j('header').removeClass( "transparent-background" );
      } else {
        $j('header').addClass( "transparent-background" );
      }
    });
  }
}

function iframeLoader() {
  if ($j(".project-brief-template").length) {
    $j(".js-project-brief-iframe").on('load', function () {
      setTimeout(function() {
        $j("#loading").addClass("loaded");
        $j(".js-project-brief-iframe").removeClass('uk-hidden');
      }, 500);
    });
  }
}

function homepageBannerHeight() {
  var totalHeight = $j(window).height();
  $j('.homepage-template .banner').height(totalHeight);
}

function navigationMobile(){
  $j(document).on("click", ".burger-btn", function(){
    mobileNavHeight();
    $j(this).parents('.navigation-ctn').toggleClass('toggled')
    $j('html').toggleClass('open');
  });

  $j(document).on("click", ".navigation-ctn nav a", function() {
    if ($j('html').hasClass('open')) {
      $j(this).parents('.navigation-ctn').removeClass('toggled')
      $j('html').removeClass('open');
    }
  })

  $j(document).on("click", "header .img-ctn a", function() {
    if ($j("html").hasClass("open")) {
      $j('html').removeClass('open');
      $j(".navigation-ctn").removeClass("toggled");
    }
  })

}

function mobileNavHeight(){
  var height;
  if($j(window).width() <= 720){
    height = $j(window).height() - 61; //top pos
  }else{
    height = 'auto';
  }

  $j('.aside-ctn').outerHeight(height);
}

function stickyButton(origPos){
  var currentScroll = $j(window).scrollTop();

  if($j(window).width() <= 720 && currentScroll > 20){
    $j('.banner-content .button').addClass('sticky');
    var elemnWidth = $j('.banner-content .sticky').outerWidth();
    var wantedWidth;

    if(elemnWidth < $j(window).width()){
      wantedWidth = currentScroll + elemnWidth;
      $j('.banner-content .sticky').css('bottom', origPos - 30 - currentScroll);
    }else{
      wantedWidth = '100%';
      $j('.banner-content .sticky').css('bottom', 0);
    }
    $j('.banner-content .sticky').width(wantedWidth);

  }else{
    $j('.banner-content .sticky').width('inherit');
    $j('.banner-content .sticky').css('bottom', 'inherit');
    $j('.banner-content .sticky').removeClass('sticky');
  }
}

// Init isotope
function initIsotope() {

  var qsRegex;
  var $projectNbr = $j(".js-project-nbr");

  // Init isotope
  var $grid = $j('.js-work-list').isotope({
    itemSelector: '.item',
    layoutMode: 'fitRows',
  });


  var iso = $j(".js-work-list").data('isotope');

  if (iso) {
    var allItems = $j(".js-work-list").isotope('getItemElements');

    // Clear values
    $j('.filters input').closest("label").removeClass("checked");
    $j('.js-search').val("");
    $j(allItems).addClass("visible");


    // - - - Filter search - - -
    var $quicksearch = $j('.js-search').on("keyup", function () {

      var filtered = [];
      $j(document).find(".all-items").removeAttr("style");
      $j(".js-filter-wrapper").addClass("has-filter");
      qsRegex = new RegExp($quicksearch.val(), 'gi');

      $grid.isotope({
        filter: function (item) {
          if (_.contains(item.classList, "visible")) {

            if (item.querySelector("h3").innerText.match(qsRegex) != null) {
              filtered.push(item.querySelector("h3").innerText)
            }

            setTimeout(() => {
              $projectNbr.text(filtered.length);
              if (filtered.length < 2) {
                $j(".js-project-text").text("project");
              } else {
                $j(".js-project-text").text("projects");
              }
            }, 50);

            var searchResult = qsRegex ? item.querySelector("h3").innerText.match(qsRegex) : true;
            return searchResult;
          }
        }
      });

    });

    // Filter selects and checkboxed - - -
    $j(".work__canvas-btn").on("click", function () {
      var filterValue;

      // Filter with selects and checkboxes
      var $selects = $j('.filters select');
      var $checkboxes = $j('.filters input');
      var inclusives = [];

      // Map input values to an array
      $selects.add($checkboxes).on("change", function () {

        $j(".js-filter-wrapper").addClass("has-filter");
        var exclusives = [];

        // Exclusive filters from selects
        $selects.each(function (i, elem) {
          if (elem.value) {
            exclusives.push(elem.value);
          }
        });

        // Inclusive filters from checkboxes
        $checkboxes.each(function (i, elem) {

          // if checkbox, use value if checked
          if (elem.checked) {
            if (!inclusives.includes(elem.value)) {
              $j(this).closest("label").addClass("checked");
              inclusives.push(elem.value);
            }
          } else {
            var index = inclusives.indexOf(elem.value);
            if (index > -1) {
              inclusives.splice(index, 1);
            }
            $j(this).closest("label").removeClass("checked")
          }
        });

        // Combine exclusive and inclusive filters
        // First combine exclusives
        exclusives = exclusives.join('');

        if (inclusives.length) {
          // Map inclusives with exclusives for
          filterValue = $j.map(inclusives, function (value) {
            return value + exclusives;
          });
          filterValue = filterValue.join('');
        } else {
          filterValue = exclusives;
        }

        $grid.isotope({ filter: filterValue })

        var allItems = $grid.isotope('getItemElements');
        var elems = $grid.isotope('getFilteredItemElements');

        if ($j(elems).length == 0) {
          $j(allItems).removeClass("visible");
          $j(allItems).addClass("hide");
        } else if ($j(elems).length > 0 && $j(elems).length < $j(allItems).length) {
          if ($j(elems).hasClass("hide")) {
            $j(allItems).removeClass("visible")
            $j(elems).removeClass("hide").addClass("visible");
          } else {
            console.log("in")
            $j(allItems).removeClass("visible")
            $j(elems).addClass("visible");
          }
        } else {
          $j(allItems).removeClass("hide").addClass("visible");
        }

        var projectDisplayed = $j(".item.visible").length;
        $projectNbr.text(projectDisplayed);

        if (projectDisplayed < 2) {
          $j(".js-project-text").text("project");
        } else {
          $j(".js-project-text").text("projects");
        }
      });

      $j(".js-reset").on("click", function() {
        $checkboxes.each(function (i, elem) {
          elem.checked = false;
        });
      })
    })
  }
}

// Debounce so filtering doesn't happen every millisecond
function debounce(fn, threshold) {
  var timeout;
  threshold = threshold || 100;
  return function debounced() {
    clearTimeout(timeout);
    var args = arguments;
    var _this = this;

    function delayed() {
      fn.apply(_this, args);
    }
    timeout = setTimeout(delayed, threshold);
  };
}

function triggerReset() {

  setTimeout(() => {
    $j(".js-reset").trigger("click");
  }, 50);
}

// Reset isotope when click on reset button
function resetIsotope() {
  $j(".js-reset").on("click", function() {
    $j(".js-filter-wrapper").removeClass("has-filter");

    var allItems = $j(".js-work-list").isotope('getItemElements');

    // Clear values
    $j(allItems).addClass("visible");

    // Update project numbre displayed
    $j(".js-project-nbr").text($j(".visible").length);

    // Reset isotope filter
    $j(".js-work-list").isotope({
      itemSelector: '.item',
      layoutMode: 'fitRows',
      filter: "*"
    })

    initIsotope();
  })
}

function removeLoaderWorkPage() {
  if ($j('.work-template').length) {
    $j("#loading").hide();
    $j("#loading").removeClass('centered');
    $j(".js-filter-wrapper").removeClass('uk-hidden');
  }
}

//****************************
// Isotope Load more button
//****************************
var initShow = 6; //number of items loaded on init & onclick load more button
var counter = initShow; //counter for load more button

// Display more work
function loadMore(container, iso, toShow) {

  container.find(".hidden").removeClass("hidden");

  var hiddenElems = iso.filteredItems.slice(toShow, iso.filteredItems.length).map(function (item) {
    return item.element;
  });

  $j(hiddenElems).addClass('hidden');
  container.isotope('layout');

  // When no more to load, hide show more button
  if (hiddenElems.length == 0) {
    $j("#load-more").hide();
  } else {
    $j("#load-more").show();
  };
}

// When load more button clicked
function loadMoreClicked(container, iso, counter) {
  $j("#load-more").on('click', function () {

    counter = counter;
    counter = counter + initShow;

    loadMore(container, iso, counter);
  });
}

// Toggle a class on hidden filters to show or hide them.
function showHideMoreFilters() {
  $j(".show-more-btn span").on("click", function() {

    var btn = $j(this);

    btn.toggleClass("active");
    btn.closest(".filters__list").find(".hide-filter").toggleClass("visible-filter");

  })
}

// Disable the enter key on search field
function disableEnterKey() {
  $j('.js-search').on('keyup keypress', function (event) {
    var key = event.keyCode || event.which;
    if (key === 13) {
      event.preventDefault();
      return false;
    }
  });
}

// Check if cookie exists to manage redirection
function checkCookie() {
  const cookies = new Cookies();
  var path = window.location.pathname.split("/")[1];

  if (cookies.get('studio') == undefined) {
    if (path != "") {
      window.location = '/';
    }
  } else {
    if (path == "") {
      window.location = '/home';
    }
  }
}